//Imports
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../middleware/config';

//Font Awesome
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function LogoutButton(){
    
    const { instance } = useMsal();

    const logoutSession = () => {
        instance.logoutRedirect(loginRequest).catch(e => {
            console.log(e.message)
        })
    }
    
    return(
        <button className="logoutButton" onClick={() => logoutSession()}><FontAwesomeIcon icon={faSignOutAlt}/></button>
    );
}
export default LogoutButton;