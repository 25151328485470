//Import
import { BrowserRouter, Routes, Route } from "react-router-dom";

//Components
import Homepage from "./pages/Homepage";

//Context
import { InfoUserProvider } from "./context/infoUser";
import { DataContextProvider } from "./context/DataContext";

import "./styles/App.scss";
import Search from "./components/Search";
import Send from "./components/Send"

function App() {
  return (
    <DataContextProvider>
      <InfoUserProvider>
        <BrowserRouter>
          <Routes>
            <Route exact={true} path="/" element={<Homepage />} />
            <Route exact={true} path="/search" element={<Search />} />
            <Route exact={true} path="/send" element={<Send />} />
          </Routes>
        </BrowserRouter>
      </InfoUserProvider>
    </DataContextProvider>
  );
}

export default App;
