import { createContext, useState } from 'react'

export const DataContext = createContext()
export const DataContextProvider = ({ children }) => {

  const [info, setInfo] = useState({
    emailsData: ['emailsData'],
    reports: {
      first: '', 
      second: '',
      third: ''
    },
    receptor : ''
  })

  return (
    <DataContext.Provider value={{ info, setInfo }}>
      {children}
    </DataContext.Provider>
  )
}
