//Imports
import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';

// Components

import Login from '../components/Login';

function Homepage(){

    const navigate = useNavigate();
    const isAuthenticated = useIsAuthenticated();
    if( isAuthenticated ){
        navigate('/search');
    }

    return(
        <>
            <AuthenticatedTemplate>
                
                <></>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <Login/>
            </UnauthenticatedTemplate>
        </>
    )
}
export default Homepage