import { useIsAuthenticated } from "@azure/msal-react"
import { useContext, useEffect, useState } from "react"
import { DataContext } from '../context/DataContext'
import { senderBack } from "../services/senderBack"
import LogoutButton from "./Logout"

import uno from "../assets/1.PNG"
import dos from "../assets/2.PNG"
import tres from "../assets/3.PNG"

import { useNavigate } from "react-router-dom"

function Search() {
  const [emails, setEmails] = useState("");
  const [reportOne, setReportOne] = useState("")
  const [reportTwo, setReportTwo] = useState("")
  const [reportThree, setReportThree] = useState("")
  const [emailReceiver, setEmailreceiver] = useState("")
  const data = {
    emails: [emails],
    reports: {
      reportOne,
      reportTwo,
      reportThree,
    },
    emailReceptor: emailReceiver,
  }

  const { info, setInfo } = useContext(DataContext)

  const handleSubmit = async (e) => {
    e.preventDefault();

    const response = await senderBack(data)
    if (response) {
      console.log(response);
      
      response.status === 200 ? navigate('/send') : console.log("")
    }
  }

  const navigate = useNavigate()

  const handleClick = () => { setInfo({
    emails: [emails],
    reports: {
      first: reportOne,
      second: reportTwo,
      third: reportThree
    },
    emailReceptor: emailReceiver
  }) }
  

  return (
    <>
      <div className='searchBox'>
        <div className='logosBox'>
          <img src={uno} alt="logo" className='logoImg' />
          <p>Sobre quienes <br/><br/><span>Emails de usuarios sobre los que se necesita información.</span></p>
          <img src={dos} alt="logo" className='logoImg' />
          <p>Qué información <br/><br/><span>Tipos de diferentes informes sobre los usuarios.</span></p>
          <img src={tres} alt="logo" className='logoImg' />
          <p>Para quién <br/><br/><span>Email de destino al que llega el Excel con la información.</span></p>
        </div>
        <DataContext.Provider value={data}>
          <form onSubmit={handleSubmit} className="form">
            <div>
              <p>Para</p>
              <input
                className="emails"
                type="text"
                placeholder="Emails de los alumnos de los que se buscan los informes"
                onChange={(eEmail) => setEmails(eEmail.target.value)} 
              />
            </div>

            <div className='reportsBox'>
              <div class="squaredOne">
                  <input type="checkbox"  onChange={(eReportOne) => setReportOne(eReportOne.target.value)} id="squaredOne" name="check" />
                  <label for="squaredOne"> </label> 
                  <p className="reports">Datos de acceso a la plataforma.</p>
              </div>

              <div class="squaredTwo">
                  <input type="checkbox"  onChange={(eReportTwo) => setReportTwo(eReportTwo.target.value)} id="squaredTwo" name="check" />
                  <label for="squaredTwo"> </label> 
                  <p className="reports">Datos de acceso y páginas visitadas.</p>
              </div>

              <div class="squaredThree">
                  <input type="checkbox"  onChange={(eReportThree) => setReportThree(eReportThree.target.value)} id="squaredThree" name="check" />
                  <label for="squaredThree"> </label>
                  <p className="reports">Datos por usuario.</p>
              </div>
            </div>

            <div>
              <p>De</p>
              <input
                className="receiver"
                type="text"
                placeholder="Email de contacto"
                onChange={(eEmailTo) => setEmailreceiver(eEmailTo.target.value)}
              />
            </div>
          <button type="submit" className="button" onClick={handleClick} >
            Enviar
          </button>
        </form>
        </DataContext.Provider>
      </div>
      <div><LogoutButton /></div>
    </>
  );
}
export default Search
