//Imports
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../middleware/config";
import globalLogo from "../assets/global-logo.PNG"

function Login() {
  const { instance } = useMsal();

  const handleLogin = () => {
    instance.loginRedirect(loginRequest).catch((e) => {
      console.log(e.message);
    });
  };

  return (
    <div className="loginPadre" >
    <div className='letterImg'>
      <div className='borderImg'> </div>
    </div>

    <div className='loginBox'>
      <img src={globalLogo} alt="Global Alumni logo" className='globalImg' />
      <p className='textTitle'>| ATHENA |</p>
      <p className='textDescription'>Envío de información a Leads</p>
      <button  className="loginButton" onClick={() => handleLogin()}>LOGIN</button>
    </div>
    </div>
  );
}
export default Login;