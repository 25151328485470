//Imports
import { createContext, useEffect, useState } from "react";
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { loginRequest } from '../middleware/config';

export const InfoUserContext = createContext();
export const InfoUserProvider = ({children}) => {

    //Guardar info del usuario
    const [infoUser, setInfoUser] = useState({name: '', email: '', department: ''});

    //Call to msal, obtener token e intercambiarlo por la infor del user
    const { instance, accounts } = useMsal();
	const saveUserData = () => {
		const request = {
			...loginRequest,
			account: accounts[0]
		}
	
		instance.acquireTokenSilent(request).then( async (response) => {
			
			//Set Info User
			setInfoUser({
				name: response.idTokenClaims.name,
				email: response.idTokenClaims.preferred_username,
				department: response.idTokenClaims.roles[0]
			});
			
		}).catch((e) => {
			instance.acquireTokenRedirect(request).then((response) => {
				console.log('Ha habido un error al traer el token', response)
			})
		})
	}

    
    //Verificar si el usuario esta autentificado
    const isAuthentificated = useIsAuthenticated();
    useEffect(() => {
        if( isAuthentificated ){
            saveUserData();
        }
    }, [isAuthentificated])

    return(
        <InfoUserContext.Provider value={{setInfoUser, infoUser}}>
            { children }
        </InfoUserContext.Provider>
    )
}
