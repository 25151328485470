import { useContext } from 'react'
import { useNavigate } from "react-router-dom"
import { DataContext } from '../context/DataContext'

import send from '../assets/Send.png'

function Send() {
  const navigate = useNavigate()

  const handleClick = () => { navigate("/Logout") }
  const handleClickReturn = () => { navigate("/Search") }
  const { info, setInfo } = useContext(DataContext)
  const { reports } = info

  return (
    <>
      <div className='sendBox'>
        <img src={send} alt="logo" className='sendImg' />
      </div>

      <div className='contextBox'>
        <div className='reportsBox'>
          <p className='title'>Informes seleccionados</p>
          <p>{(reports.first) ? `Informe 1 :${reports.first}` : ''}</p>
          <p>{(reports.second) ? `Informe 2 :${reports.second}` : ''}</p>
          <p>{(reports.third) ? `Informe 3 :${reports.third}` : ''}</p>
        </div>

        <div className='emailsBox'>
          <p className='title'>Emails seleccionados</p>
          <p>{info.emails}</p>
        </div>
        <div className='receptorBox'>
          <p className='title'>Email de recepción</p>
          <p>{info.emailReceptor}</p>
        </div>
      </div>

      <div className='buttonsBox'>
        <button className="buttonReturn" onClick={handleClickReturn} >
          Enviar otro informe
        </button>

        <button className="buttonExit" onClick={handleClick} >
          Salir
        </button>
      </div>
    </>
  )
}
export default Send
